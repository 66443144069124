import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Const } from "@app/const/Const";
import { BaseComponent } from "@abstract/BaseComponent";
import { AddCarrierSaleRep } from "@app/admin/dispatch/components/sales/add-carrier-sale-rep";
import { EditCarrierBidSettings } from "../edit-settings";
import { ViewPreviousRouteComponent } from "../../view-previous-route/view-previous-route.component";
import { AddTargetRate } from "@app/admin/carrier-sales-v2/target-rate";
import { EditOptionalEquipment } from "../edit-optional-equipment";
import { DialogService } from "@dialogs/dialog.service";
import { InputHelper } from "@services/input-helper";
import { Log } from "@services/log";

@Component({
  selector: '[basic-setting]',
  templateUrl: "./index.html",
  styleUrls: ["../detail-carrier-bid.component.scss", "./style.scss"],
})

export class BasicSettingComponent extends BaseComponent {
  @Input() model: any;
  @Input() jobLink: any;
  @Input() onHold;
  @Input() get displayInfo() {
    return this._displayInfo;
  }
  @Input() avgCostOfRoutes: number;
  @Output() onReload: EventEmitter<any> = new EventEmitter<any>();
  @Output() setAvgCost: EventEmitter<any> = new EventEmitter<any>();
  private _displayInfo: any;

  set displayInfo(value) {
    this._displayInfo = {
      ...value,
      firstCommodity: this.getFirstCommodity(),
      allCommodities: this.getAllCommodities(),
    }
    if (value.isShowRemainingTimePickup) {
      if (this.timer) clearInterval(this.timer);
      this.calculateRemainingTime();
      this.timer = setInterval(() => {
        this.calculateRemainingTime();
      }, 1000);
    }
  }


  private getFirstCommodity() {
    if (this.model?.job?.totalShipmentsItems?.commodity?.length) {
      return this.model.job.totalShipmentsItems.commodity[0];
    }
    else return "";
  }

  private getAllCommodities() {
    return this.model?.job?.totalShipmentsItems?.commodity?.join(', ');
  }

  ngOnDestroy(): void {
    this.timer && clearInterval(this.timer)
  }

  remainingTime: any = {};
  timer = null;
  calculateRemainingTime() {
    if (!this.model?.pickupDateOriginal) return;
    const currentTime = new Date();
    const difference = new Date(this.model.pickupDateOriginal).getTime() - currentTime.getTime();
    this.remainingTime.isNegative = difference < 0;
    const absoluteDifference = Math.abs(difference);
    this.remainingTime.days = Math.floor(absoluteDifference / (1000 * 60 * 60 * 24));
    this.remainingTime.hours = Math.floor((absoluteDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    this.remainingTime.minutes = Math.floor((absoluteDifference % (1000 * 60 * 60)) / (1000 * 60));
    this.remainingTime.seconds = Math.floor((absoluteDifference % (1000 * 60)) / 1000);
  }

  getMoneyValue(value) {
    return '$' + InputHelper._formatMoney(value, 0);
  }

  onBtnEditCarrierSalesRep() {
    if (!this.model?.jobId) return;
    const carrierSalesRepId = this.model?.job?.carrierSalesRep?.id;
    DialogService.openFormDialog1(AddCarrierSaleRep, {
      nzComponentParams: {
        jobId: this.model.jobId,
        carrierSalesRepId: carrierSalesRepId,
        closeOnSuccess: true,
        updateSuccess: resp => {
          this.onReload.emit();
        }
      },
      nzClassName: 'modal-no-padding',
    })
  }

  onReUpdateAvgPastRate(event) {
    this.updateAvgPastRate({});
  }

  public get carrierBidId() {
    return this.model?.id
  }

  isLoadingAvg = false;
  private updateAvgPastRate(params) {
    if (!this.model?.jobId) return;
    this.isLoadingAvg = true;
    this.api.POST(Const.APIV2(`${Const.APIURI_JOBS}/${this.model.jobId}/update_avg_past_rate`), params).subscribe(
      resp => {
        if (resp?.data && this.model?.job) {
          this.avgCostOfRoutes = resp?.data;
          this.setAvgCost.emit(resp?.data);
        }
        this.isLoadingAvg = false;
      }, err => {
        this.isLoadingAvg = false;
        Log.e(err);
      }
    );
  }

  public onBtnOnUpdateTargetRate() {
    DialogService.openFormDialog1(AddTargetRate, {
      nzComponentParams: {
        data: this.model?.job?.datRate?.cost_update?.cost,
        closeOnSuccess: true,
        onSave: data => this.updateDatRate(data)
      },
    });
  }

  onReUpdateDatRate(event) {
    this.updateDatRate({});
  }

  isLoadingDatRate: boolean = false;
  private updateDatRate(params) {
    if (!this.model?.jobId) return;
    this.isLoadingDatRate = true;
    this.api.PUT(`${Const.APIURI_JOBS}/${this.model.jobId}/update_dat_rate_for_job`, params).subscribe(
      resp => {
        if (resp?.data?.total_charge && this.model?.job) {
          this.model.job.datRate = resp?.data;
        }
        this.isLoadingDatRate = false;
      }, err => {
        this.isLoadingDatRate = false;
        Log.e(err);
      }
    );
  }

  onBtnEditSettings() {
    DialogService.openFormDialog1(EditCarrierBidSettings, {
      nzComponentParams: {
        model: {
          type: this.model?.type,
          isAllowPlaceBid: this.model?.isAllowPlaceBid,
          isShowBasePrice: this.model?.isShowBasePrice,
          basePrice: this.model?.basePrice,
          isShowOnBidBoard: this.model?.isShowOnBidBoard,
          isRequireCarrierAcceptLoadTender: this.model?.isRequireCarrierAcceptLoadTender,
        },
        isSourceMarketPlace: this.displayInfo.isMarketplace,
        onSave: data => {
          let url = `${Const.APIURI_CARRIER_BIDS}/${this.carrierBidId}/settings`;
          return this.api.PUT(url, data)
        },
        updateSuccess: resp => {
          this.onReload.emit();
        }
      },
      nzClassName: 'modal-no-padding',
    })
  }

  onBtnPreviousRoute() {
    DialogService.openFormDialog1(ViewPreviousRouteComponent, {
      nzComponentParams: {
        model: this.model,
        closeOnSuccess: true,
        updateSuccess: (resp) => {
          this.onReload.emit();
        },
      },
      nzClassName: "view-previous-route-form modal-xl",
    });
  }

  onBtnEditOptionalEquipments() {
    DialogService.openFormDialog1(EditOptionalEquipment, {
      nzComponentParams: {
        model: {
          optionalEquipments: this.model?.optionalEquipments || []
        },
        onSave: data => {
          let url = `${Const.APIURI_CARRIER_BIDS}/${this.carrierBidId}/update-optional-equipment`;
          return this.api.PUT(url, data)
        },
        updateSuccess: resp => {
          this.onReload.emit();
        }
      },
      nzClassName: 'modal-no-padding',
    })
  }
}