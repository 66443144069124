import { Component } from "@angular/core";
import { Const } from "@const/Const";
import { ApiService } from "@services/api.service";
import { InputHelper } from "@app/services/input-helper";
import _ from 'underscore';
import { DialogService } from "@dialogs/dialog.service";
import { EditCarrierBidAnswerComponent } from "../edit-carrier-bid-answer/edit-carrier-bid-answer.component";
import { DateUtil } from "@services/date-utils";
import { getDashboard } from "@services/index";
import { BaseDetail } from "@app/admin/base/detail";
import { ActivatedRoute } from "@angular/router";
import { SendMessageComponent } from "../send-message/send-message.component";
import { EditNoteComponent } from "../edit-note/edit-note.component";
import { BizUtil } from "@services/biz";
import { AddCarrier } from "./add-carrier";
import { AddCarrierPool } from "./add-carrier-pool";
import { CommLogList } from "@components/comm-log";
import { AddCarrierPoolByCoverage } from "./add-carrier-pool-by-coverage";
import { SelectCarrierByServiceArea } from "./add-carrier-by-coverage";
import { ListShipmentExpandUtil } from "@services/list-shipment-expand-util";
import { Utils } from "@services/utils";
import { AssignCarrier } from "@app/admin/carrier-bids/components/assign-carrier";
import { EMPTY, Subject } from "rxjs";
import { Log } from '@services/log';
import { debounceTime, switchMap } from "rxjs/operators";
import { Const as WarpConst } from "@wearewarp/universal-libs";
import dayjs from "dayjs";
import to from 'await-to-js';
import { EditCounterComponent } from "../edit-counter/edit-counter.component";
import { CounterForCarrierComponent } from "../counter-for-carrier/counter-for-carrier.component";
import { CarrierBid } from "@wearewarp/types/data-model";

const API_URLS = {
  GET_CARRIER_BIDS: Const.APIURI_CARRIER_BIDS,
};
const CHECKSTATUS = {
  NO_ITEM_CHECKED: 0,
  AT_LEAST_ONE_ITEM_CHECKED: 1,
  ALL_ITEMS_CHECKED: 2
}
@Component({
  selector: "app-detail-carrier-bid",
  templateUrl: "./detail-carrier-bid.component.html",
  styleUrls: ["./detail-carrier-bid.component.scss"],
})
export class DetailCarrierBidComponent extends BaseDetail {

  public isError = false;
  public isLoading = false;
  public data: any = {};
  protected get crudEntity(): string { return 'carrier_bids' }
  public keywordSearchName: string;
  public keywordSearchPoolName: string;
  public keywordSearchContacts: string;
  public listData: any = [];
  public shipments: any[] = [];
  public sortPriceDirection = 'ascend';

  protected subscribeTopbarTitle(fn: Function) {
    return fn("View Carrier Bid");
  }

  private listCarrierOriginal: any[];     // lưu mảng carrier ban đầu trước khi convert/group
  public listCarrierWithGroup: any[];     // mảng sau khi đã group theo dedicated pool

  public avgCostOfRoutes: any //giá bid trung bình của route này
  public earliestBid: any //thời gian phản hồi bid sớm nhất
  public lowestPrice: any //giá bid thấp nhất
  public bestExperience: any //chạy tuyến hiện tại nhiều nhất
  public basePrice: any //giá bid mà WARP offer
  public acceptedPrice: any //giá bid đã chốt
  public acceptedCarrier: any
  public displayInfo: any = {};
  public get canPerformAction(): boolean { return true }

  public get checkedStatus() {
    const carriers = this.data.carriers || [];
    let countChecked = carriers.filter(carrier => carrier.checked).length
    if (countChecked == 0) return CHECKSTATUS.NO_ITEM_CHECKED;                   // No item is checked
    if (countChecked == carriers.length) return CHECKSTATUS.ALL_ITEMS_CHECKED;     // All items are checked
    return CHECKSTATUS.AT_LEAST_ONE_ITEM_CHECKED;                                         // A few items (not all) are checked
  }

  shouldShowBtnDat = false;
  datPostExist = false;

  public tabSelected: number = 0;
  private tabIndexs = {
    'carrier-matches': 0,
    'truck-search': 1,
    'outreach-performance': 2,
  }

  constructor(activatedRoute: ActivatedRoute, api: ApiService) {
    super(activatedRoute);
    this.api = api;
    this.activatedRoute.params.subscribe(params => {
      super.handleNavigationEnd(activatedRoute.toString(), activatedRoute.queryParamMap);
    })
  }

  public jobLink;

  public get carrierBidId() {
    return this.id
  }
  
  ngOnInit(): void {
    super.ngOnInit();
    this.searchSubject.pipe(
      debounceTime(150),
      switchMap((query: string) => {
        this.onFilterData();
        return EMPTY;
      })
    ).subscribe(result => { });
    setTimeout(() => getDashboard().sideBar.isSmallWidth = true, 1);
    this.getDatLoad();
  }

  public mapOfExpandedData: { [key: string]: TreeNodeInterface[] } = {};

  toggleChildren(originalItem: TreeNodeInterface, mappedItem: TreeNodeInterface) {
    ListShipmentExpandUtil.toggleChildren(originalItem, mappedItem, this.mapOfExpandedData);
  }

  onExpandChange(array: TreeNodeInterface[], data: TreeNodeInterface, isExpanded: boolean) {
    ListShipmentExpandUtil.collapse(array, data, isExpanded);
  }

  getTreeNodeId(mappedItem: TreeNodeInterface) {
    let item: any = mappedItem.parent ?? mappedItem;
    return item.id;
  }

  getTagsData(listData) {
    let bidDates: number[] = []
    let bidPrices: number[] = []
    let countLanes: number[] = []

    for (let item of listData) {
      //date
      const when = item?.when && new Date(item?.when)?.getTime()
      if (when) bidDates.push(when)
      //price
      const price = !isNaN(item?.price) && item?.price
      if (price) bidPrices.push(price)
      //count lane completed
      const countLane = !isNaN(item?.countLane) && item?.countLane
      if (countLane) countLanes.push(countLane)
    }
    return {
      earliestBid: Math.min(...bidDates),
      lowestPrice: Math.min(...bidPrices),
      bestExperience: Math.max(...countLanes)
    }
  }

  private addTagsData(listData, earliestBid, lowestPrice, bestExperience) {
    let newData = []
    for (let item of listData) {
      //isEarliestBid
      if (item?.when && new Date(item.when).getTime() == earliestBid) item.isEarliestBid = true
      //isLowestPrice
      if (item?.price && item?.price == lowestPrice) item.isLowestPrice = true
      //bestExperience
      if (item?.countLane && item?.countLane == bestExperience) item.isBestExperience = true

      newData.push(item)
    }
    return newData
  }

  private processListCarrier(listData) {
    this.mapOfExpandedData = ListShipmentExpandUtil.processListData(listData);
    const listCarrierNoGroup = [];
    for (let arr of Object.values(this.mapOfExpandedData)) {
      for (let item of arr) {
        if (this.isGroupByPool(item)) {
          continue;
        }
        listCarrierNoGroup.push(item);
      }
    }
    this.data.carriers = listCarrierNoGroup;
  }

  isGroupByPool(item): boolean {
    return (<string>item.key)?.startsWith('pool-');
  }

  // Những thằng nào nằm trong cùng 1 dedicated pool thì gom thành 1 nhóm
  private groupByDedicatedPool(bidDetail) {
    const carriers: any[] = bidDetail.carriers ?? [];
    const pools: any[] = bidDetail.pools ?? [];
    const dedicatedPools = {};
    for (let pool of pools) {
      if (pool.isDedicatedPool) {
        dedicatedPools[pool.id] = pool;
      }
    }
    const arr = [];
    const dicGroups = {};
    for (let carrier of carriers) {
      carrier.key = `carrier-${carrier.carrierId}`;     // dùng cho TreeNodeInterface
      let poolData: any = { isDedicatedPool: false }
      if (carrier.poolId && dedicatedPools[carrier.poolId]) {
        poolData = {
          isDedicatedPool: true,
          poolName: dedicatedPools[carrier.poolId].name
        }
      }
      arr.push({ ...carrier, ...poolData });
    }

    if (!Utils.isObjectNotEmpty(dicGroups)) return arr;

    let groups: any[] = Object.values(dicGroups);
    for (let group of groups) {
      group.children = this.sortCarriers(group.children);
    }
    return [...groups, ...arr];
  }

  private sortCarriers(carriers: any[]) {
    const sortPriceDirection = this.sortPriceDirection;
    return _(carriers).chain()
      .sortBy(function (patient) {
        return patient.title;
      })
      .sortBy(function (patient) {
        return (patient.status) * -1; //reverse sort => sort status desc
      })
      .sortBy(function (patient) {
        if (sortPriceDirection == 'descend') return (patient.price || 0) * -1;
        return (patient.price || 0); //reverse sort => sort price desc
      })
      .sortBy(function (patient) {
        return patient.lastSent ? -1 : 0; //sort lastSent. những carrier đã sent email sẽ lên trước.
      })
      .sortBy(function (patient) {
        return (patient.state || 0) * -1; //reverse sort => sort tag desc
      })
      .value();
  }

  public totalCount: number = 0;
  private bid: CarrierBid;
  getData() {
    if (!this.carrierBidId) return;
    this.isLoading = true;
    this.isLoadingCarrier = true;
    this.currentPage = 1;
    const skip = this.currentPage ? (this.currentPage - 1) * 10 : 0;

    let urls = [
      `${API_URLS.GET_CARRIER_BIDS}/${this.carrierBidId}`,
      `${API_URLS.GET_CARRIER_BIDS}/${this.carrierBidId}/get_list_carriers?skip=${skip}`
    ];
    this.api.concurrentGET(urls).subscribe(
      resp => {
        const bid = resp[0].data;
        this.bid = bid;
        const carriers = resp[1].data.list_data;
        this.totalCount = resp[1].data.total;
        this.processDataBid({ ...bid, carriers })
        this.isLoading = false;
        this.isLoadingCarrier = false;
      }, err => {
        this.isLoading = false;
        this.isLoadingCarrier = false;
        this.showErr(err);
        this.isError = true;
      }
    );
  }

  public isLoadingCarrier: boolean = false;
  private async getListCarriers() {
    this.isLoadingCarrier = true;
    const skip = this.currentPage ? (this.currentPage - 1) * 10 : 0;
    const [err, result] = await to(this.api.GET(`${API_URLS.GET_CARRIER_BIDS}/${this.carrierBidId}/get_list_carriers?skip=${skip}`).toPromise());
    this.isLoadingCarrier = false;
    if(err) {
      this.showErr(err);
      return;
    }
    const carriers = result.data.list_data;
    this.totalCount = result.data.total;
    this.processDataBid({ ...this.bid, carriers })
  }

  private processDataBid(data) {
    if (data.job) this.jobLink = BizUtil.createHyperLinkForJob(data.job);

    //convert data
    let carriers = data.carriers || [];
    for(let carrier of carriers){
      carrier.bidAnswers = this.getCounterBidHistory(carrier);
    }
    data.carriers = carriers;
    this.data = data;
    this.listCarrierOriginal = data.carriers;
    this.listCarrierWithGroup = this.sortCarriers(this.groupByDedicatedPool(data));
    this.listData = this.listCarrierWithGroup;

    //start: xử lý earliest bid, lowest price, route completed
    const { earliestBid, lowestPrice, bestExperience } = this.getTagsData(this.listData)
    this.earliestBid = earliestBid
    this.bestExperience = bestExperience //chạy tuyến này nhiều nhất
    this.lowestPrice = lowestPrice //giá thấp nhất
    this.basePrice = this.data?.basePrice //giá offer
    this.avgCostOfRoutes = this.data?.job?.avgPastRates;
    this.listCarrierWithGroup = this.addTagsData(this.listCarrierWithGroup, earliestBid, lowestPrice, bestExperience)
    // xử lý info cho accepted carrier
    this.acceptedPrice = this.data?.job?.assignedCarrier?.cost?.grandTotal
    this.acceptedCarrier = this.data?.job?.carrier
    const findCarrier = this.listCarrierWithGroup?.find(carrier => carrier?.carrierId == this.acceptedCarrier?.id)
    if (findCarrier) {
      this.acceptedCarrier = {
        ...this.acceptedCarrier,
        isLowestPrice: findCarrier?.isLowestPrice,
        isEarliestBid: findCarrier?.isEarliestBid,
        isBestExperience: findCarrier?.isBestExperience
      }
    }
    //end: xử lý earliest bid, lowest price, route completed

    this.processListCarrier(this.listCarrierWithGroup);

    const pickupTimeZone = data["pickupAddress"]?.metadata?.timeZoneStandard;
    const dropoffTimeZone = data["dropoffAddress"]?.metadata?.timeZoneStandard;
    data['pickupDateOriginal'] = Utils.cloneObject(data['pickupDate']);
    data['pickupDate'] = data['pickupDate'] ? DateUtil.convertLocalTime2(data['pickupDate'], pickupTimeZone) : null;
    data['dropoffDate'] = data['dropoffDate'] ? DateUtil.convertLocalTime2(data['dropoffDate'], dropoffTimeZone) : null;

    data.equipments = data.equipments.map((item) => item.id);
    data.pickupAddressObj = data.pickupAddress
    data.dropoffAddressObj = data.dropoffAddress
    data.pickupAddress = this.getAddressTxtCarrierBid(data.pickupAddress);
    data.dropoffAddress = this.getAddressTxtCarrierBid(data.dropoffAddress);
    this.setFormValues(data);

    this.model = data;
    this.processShipmentsOnHold(this.model?.jobId);

    if (data.job?.shipments?.length) {
      this.data.populated = true
    }
    this.buildDisplayInfo();
    if (this.canPerformAction) {
      const params = { canPerformAction: true };
      this.updateDatRate(params);
    }
  }

  private buildDisplayInfo() {
    this.displayInfo = {
      isGhostLoad: this.model?.job?.type === WarpConst.JobType.ghost,
      isMarketplace: this.model?.job?.type === WarpConst.JobType.ghost && this.model?.job?.source == WarpConst.JobSources.marketplace,
      clientName: this.model?.job?.clients?.[0]?.name || 'N/A',
      numOfClient: this.model?.job?.clients?.length ?? 0,
      arrClientName: this.model?.job?.clients?.map(it => it.name) || [],
      mileage: this.getMileage(),
      carrierSalesRep: this.getFullName(this.model?.job?.carrierSalesRep),
      isShowRemainingTimePickup: this.isShowRemainingTimePickup(),
      vehicles: this.getVehicles(),

      tempRange: this.model?.job?.tempRange,
      numOfServiceOptions: this.model?.serviceOptions?.length ?? 0,
      firstServiceOptions: this.model?.serviceOptions?.[0]?.name ?? '',
      allServiceOptions: this.model?.serviceOptions?.map(it => it.name)?.join(', '),
      numOfOptionalEquipments: this.model?.optionalEquipments?.length ?? 0,
      firstOptionalEquipments: this.model?.optionalEquipments?.[0]?.label ?? '',
      allOptionalEquipments: this.model?.optionalEquipments?.map(it => it.label)?.join(', '),
      totalShipmentCost: this.model?.job?.totalShipmentsCost,
      totalShipmentCostPerMile: this.getTotalShipmentCostPerMile(),
      bidModeType: this.getLabelType(this.model?.type),
      warpOffer: Utils.isNumber(this.model?.basePrice) ? InputHelper.formatMoney2(String(this.model.basePrice)) : null,
    }
  }

  public currentPage: number = 1;
  public async changePage(value) {
    this.mode = '';
    this.currentPage = value;
    this.getListCarriers();
  }

  private isShowRemainingTimePickup() {
    if (this.model?.job?.assignedCarrier?.carrierId) {
      return false;
    }
    if (!this.model?.pickupDate) return false;
    return true;
  }

  private getMileage(): string {
    const totalDistance = this.model?.job?.totalDistance;
    if (!totalDistance) return '';
    return (totalDistance / 1609.34).toFixed(2).toLocaleString();
  }

  private getVehicles(): any[] {
    let vehicles = this.model?.vehicles;
    if (!vehicles?.length) return [];
    vehicles = vehicles.filter(x => x);
    return vehicles.map(vehicle => {
      if (vehicle.options?.length) {
        return `${vehicle.name} /w ${vehicle.options.map(Utils.capitalize).join(", ")}`
      }
      return vehicle.name
    });
  }

  isLoadingDatRate = false;
  private updateDatRate(params) {
    if (!this.model?.jobId) return;
    this.isLoadingDatRate = true;
    this.api.PUT(`${Const.APIURI_JOBS}/${this.model.jobId}/update_dat_rate_for_job`, params).subscribe(
      resp => {
        if (resp?.data?.total_charge && this.model?.job) {
          this.model.job.datRate = resp?.data;
        }
        this.isLoadingDatRate = false;
      }, err => {
        this.isLoadingDatRate = false;
        Log.e(err);
      }
    );
  }

  private processShipmentsOnHold(jobId: string) {
    if (!jobId) return;
    const url = Const.APIV2(`${Const.APIURI_JOBS}/${jobId}/${Const.APIURI_SHIPMENTS}`);
    this.api.GET(url).subscribe(
      resp => {
        this.shipments = resp?.data?.list_data;
        this.processOnHold();
      }, err => {
        this.showErr(err);
      }
    );
  }

  private getTotalShipmentCostPerMile() {
    let mileage: any = this.getMileage();
    if (!mileage) return null;
    mileage = parseFloat(mileage);
    const totalShipmentCost = InputHelper.getValueMoney(this.model?.job?.totalShipmentsCost)
    if (mileage && Utils.isNumber(totalShipmentCost)) {
      return `$${(totalShipmentCost / mileage).toFixed(2)}/mi`;
    }
    return null;
  }

  onHold: string[] = []
  processOnHold() {
    this.onHold = (this.shipments || []).filter(it => it.tags && it.tags.indexOf('HOLD') >= 0).map(it => it.warpId);
  }

  getAddressTxtCarrierBid(addr) {
    if (!addr) return '';
    let txt = `${addr.city}, ${addr.state}, ${addr.zipcode}`;
    if (addr.street) {
      txt = `${addr.street}, ${txt}`;
    }
    return txt;
  }

  onBtnAcceptBid(carrier: any) {
    let cost = this.model.baseRate?.grandTotal === carrier.price ? this.model.baseRate : this.makeCost(carrier)
    DialogService.openFormDialog1(AssignCarrier, {
      nzComponentParams: {
        jobId: this.data.jobId,
        isRequireCarrierAcceptLoadTender: this.model?.isRequireCarrierAcceptLoadTender ?? false,
        matchedCarrier: carrier,
        cost: cost,
        job: this.data?.job,
        closeOnSuccess: true,
        updateSuccess: resp => {
          this.onBtnRefresh()
          this.showDialog(`Carrier has been assigned successfully.<br /><br />
            <a href="${this.gotoDispatch()}" target="_blank">Go to dispatch</a>
          `);
        }
      },
      nzClassName: 'modal-no-padding assign-carrier-form',
    });
  }

  onBtnHistoryStatus(item) {
    const { contacts } = item;
    let allHistories = [];
    for (let contact of contacts) {
      let histories = contact?.histories || [];
      if (histories.length) histories = histories.map(it => {
        return { ...it, email: contact.contactEmail, phone: contact.contactPhone }
      })
      allHistories = [...allHistories, ...histories];
    }

    allHistories = allHistories.sort(function (a, b) {
      let bDate = new Date(b.when)
      let aDate = new Date(a.when)
      return Number(bDate) - Number(aDate)
    });

    if (!allHistories.length) return;
    DialogService.openFormDialog1(CommLogList, {
      nzComponentParams: {
        model: { histories: _.uniq(allHistories, it => it.logId) },
        closeOnSuccess: true
      },
      nzClassName: "comm-log-form modal-no-padding modal-xl",
    });
  }

  $asSentStatusText = (status) => {
    if (!status) return '';
    status = this.mapStatus(status);

    return this.capitalizeFirstLetter(status);
  };

  mapStatus(status) {
    if (!status) return '';
    status = status.toLowerCase();
    const statuses = {
      pending: 'pending',
      sent: 'sent',
      failed: 'failed',
      success: 'success',
      delivered: 'delivered',
      opened: 'opened',
      clicked: 'clicked',
      unsubscribed: 'unsubscribed',
      undelivered: 'undelivered',
      invalid: 'invalid',
      unreachable: 'unreachable',
      unknown: 'unknown'
    }

    return statuses[status] || status;
  }

  $asSentStatusColor = (status) => {

    switch (status) {
      case 'pending':
        return 'gray';
      case 'sent':
      case 'success':
        return 'green';
      case 'failed':
      case 'invalid':
        return 'red';
      case 'delivered':
      case 'opened':
      case 'clicked':
        return 'blue';
      case 'undelivered':
      case 'unreachable':
      case 'unknown':
        return 'orange';
      case 'unsubscribed':
        return 'black';
      default:
        return '';
    }
  }

  $shouldShowHistory = (item) => {
    return item?.logId
  }

  private makeCost(data) {
    return {
      currency: {
        type: "USD",
        fxRate: null
      },
      transitCost: {
        rate: data.price,
        qty: 1,
        total: data.price
      },
      volumeDiscount: {
        type: "percentage",
        percentage: null,
        flatRate: null,
        qty: null,
        total: 0
      },
      subTotal: data.price,
      fuelCost: {
        type: "rpm",
        percentage: null,
        rpm: null,
        qty: null,
        total: 0
      },
      serviceOptions: [],
      negativeMarginReason: null,
      manager: null,
      grandTotal: data.price,
      usdConversion: 0
    }
  }

  async acceptBid(carrier) {
    const params = {
      jobId: this.data.jobId,
      carrierId: carrier.carrierId,
      cost: this.makeCost(carrier)
    };
    const resp = await this.api.POST(`${Const.APIURI_CARRIER_BIDS}/accept-bid`, params).toPromise().catch(err => {
      this.showErr(err);
    });

    if (resp) {
      this.onBtnRefresh();
      this.showDialog(`Carrier has been assigned successfully.<br /><br />
        <a href="${this.gotoDispatch()}" target="_blank">Go to dispatch</a>
      `);
    }
  }

  gotoDispatch() {
    return `${Const.routeAdminDispatchList}/${this.data.jobId}`
  }

  onBtnSendMessage() {
    if(this.mode === 'not_sent' && !this.data?.metadata?.notSentCount) return;
    DialogService.openFormDialog1(SendMessageComponent, {
      nzComponentParams: {
        model: { ...this.data },
        mode: this.mode,
        closeOnSuccess: true,
        updateSuccess: (resp) => {
          this.getData()
        },
      },
      nzClassName: "send-message-form modal-xl",
    });
  }

  onBtnEditItem(item) {
    DialogService.openFormDialog1(EditCarrierBidAnswerComponent, {
      nzComponentParams: {
        carrierBidItem: item,
        carrierBidInfo: this.data,
        closeOnSuccess: true,
        updateSuccess: (resp) => {
          this.getData()
        },
      },
      nzClassName: "modal",
    });
  }

  onBtnEditNote(item) {
    DialogService.openFormDialog1(EditNoteComponent, {
      nzComponentParams: {
        carrierBidItem: item,
        carrierBidInfo: this.data,
        closeOnSuccess: true,
        updateSuccess: (resp) => {
          //this.listData[index].carrier = resp.data.assignedCarrier.carrierObj;
          this.getData()
        },
      },
      nzClassName: "modal modal-lg",
    });
  }

  private mode: string = '';
  onCheckedChange(event, key = 'not_sent') {
    if (key === 'all' && event) {
      this.mode = 'all';
      this.data.carriers.map(carrier => carrier['checked'] = event);
    }
    if (key === 'not_sent' && event) {
      this.mode = 'not_sent';
      this.data.carriers.map(carrier => {
        if (!carrier?.lastSent?.when) carrier['checked'] = true;
        else carrier['checked'] = false;
        return carrier;
      });
    }
    if (!event) {
      this.mode = '';
      this.data.carriers.map(carrier => carrier['checked'] = false);
    }
    this.reRenderListCarrier(this.data.carriers);
  }

  listOfSelection = [
    {
      text: 'Select all carriers',
      onSelect: () => {
        this.onCheckedChange(true, 'all');
      }
    }
  ];

  onBtnBack() {
    this.location.back()
  }

  onBtnEdit() {
    this.router.navigate([this.routeAdminCarrierSales, 'edit', this.data.id])
  }

  onBtnGoToJob() {
    this.router.navigate([this.routeAdminDispatchList, this.data.jobId])
  }

  onBtnDatLoad() {
    if (!this.datPostExist) {
      this.router.navigate([this.routeAdminCarrierSales, this.data.id, 'dat-load', 'add'])
    } else {
      this.router.navigate([this.routeAdminCarrierSales, this.data.id, 'dat-load'])
    }
  }

  $asCarrierStatusText = (status) => {
    function capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
    const statusKey = Object.keys(Const.CarrierStatus).filter((key) => Const.CarrierStatus[key] == status)[0] || "";
    return capitalizeFirstLetter(statusKey);
  };

  $asMoney = (money) => {
    return InputHelper.formatMoney1(money + '');
  };

  $formatDate = (date) => {
    return DateUtil.dateToString(date, Const.FORMAT_GUI_DATETIME_SHORT);
  }

  $displayEstTime = (date) => {
    return dayjs(date).tz('America/New_York').format('MMM DD, hh:mm A');
  }

  openAddCarrierModal() {
    DialogService.openFormDialog1(AddCarrier, {
      nzComponentParams: {
        model: this.model,
        closeOnSuccess: true,
        updateSuccess: (resp) => {
          this.getData()
        },
      },
      nzClassName: "send-mail-form modal-xl",
    });
  }

  onClickPoolCoverage() {
    DialogService.openDialog(AddCarrierPoolByCoverage, {
      nzComponentParams: {
        model: this.data,
        onSave: this.addCarrierByCoverage.bind(this)
        // closeOnSuccess: true,
        // updateSuccess: (resp) => {
        //   this.getData()
        // },
      },
      nzClassName: "add-carrier-pool-by-coverage modal-xxl",
    });
  }

  onAddCarriersByCoverage() {
    DialogService.openDialog(SelectCarrierByServiceArea, {
      nzComponentParams: {
        model: this.data,
        onSave: this.addCarrierByCoverage.bind(this)
        // closeOnSuccess: true,
        // updateSuccess: (resp) => {
        //   this.getData()
        // },
      },
      replaceWrapClassName: true,
      nzClosable: false,
      nzClassName: "add-carrier-pool-by-coverage modal-xxl",
    });
  }

  addCarrierByCoverage(carrierIds: string[] = []) {
    let carriers = carrierIds.map(id => ({ carrierId: id }))
    this.api.POST(`${Const.APIURI_CARRIER_BIDS}/${this.model?.id}/update-carrier`, {
      carriers: carriers
    }).subscribe(
      (response) => {
        this.getData()
        this.showInfo('Add Carriers successfully');
      },
      (err) => {
        this.showErr(err);
      }
    );
  }

  openAddCarrierPoolModal() {
    DialogService.openFormDialog1(AddCarrierPool, {
      nzComponentParams: {
        model: this.model,
        closeOnSuccess: true,
        updateSuccess: (resp) => {
          this.getData()
        },
      },
      nzClassName: "add-carrier-pool-form modal-xl",
    });
  }

  confirmRemoveCarrier() {
    this.confirmDeletion({
      message: `Are you sure you want to remove selected carrier ?`,
      fnOk: () => {
        const carriersRemovedIds = this.data?.carriers?.filter(carrier => carrier.checked).map(it => it.carrierId)
        this.api.POST(`${Const.APIURI_CARRIER_BIDS}/${this.model?.id}/update-carrier`, { deletedCarrierIds: carriersRemovedIds }).subscribe(
          (response) => {
            this.keywordSearchName = '';
            this.keywordSearchPoolName = '';
            this.keywordSearchContacts = ''
            this.getData()
            this.showInfo('Remove successfully');
          },
          (err) => {
            this.showErr(err);
          }
        );
      },
      txtBtnOk: 'Remove'
    });
  }

  get isShowWarpOffer() {
    return this?.formInput?.get('isShowBasePrice')?.value;
  }

  carrierBIDType = Object.values(Const.CarrierBidTypes);

  getLabelType(key) {
    switch (key) {
      case Const.CarrierBidTypes.direct: return 'Auto assign when carrier accept load (Direct)';
      case Const.CarrierBidTypes.manual: return 'Manual review (Indirect)';
      default: return 'Manual';
    }
  }

  onItemCheckedChange(event, item) {
    this.mode = '';
    (this.data.carriers?.find(it => it.carrierId == item.carrierId)).checked = event;
  }

  private searchSubject = new Subject<string>();
  onSearch(value: string, type: string) {
    this.data.carriers.map(carrier => {
      if (carrier['checked']) carrier['checked'] = false;
    });
    if (type == 'name') this.keywordSearchName = value;
    if (type == 'contacts') this.keywordSearchContacts = value;
    if (type == 'poolName') this.keywordSearchPoolName = value;
    this.searchSubject.next(value);
  }

  private onFilterData() {
    if (!this.keywordSearchName && !this.keywordSearchContacts && !this.keywordSearchPoolName) {
      this.reRenderListCarrier(this.listCarrierOriginal);
      return;
    }
    this.reRenderListCarrier(this.listCarrierOriginal.filter(carrier => this.matchedCarrier(carrier)));
  }

  private reRenderListCarrier(carriers) {
    this.data.carriers = carriers.filter(carrier => this.matchedCarrier(carrier));
    this.listCarrierWithGroup = this.sortCarriers(this.groupByDedicatedPool(this.data));
    this.mapOfExpandedData = ListShipmentExpandUtil.processListData(this.listCarrierWithGroup);
    this.listData = this.listCarrierWithGroup;
  }

  private matchedCarrier(carrier) {
    let matched = this.filterName(this.keywordSearchName, carrier)
      && this.filterPoolName(this.keywordSearchPoolName, carrier)
      && this.filterContact(this.keywordSearchContacts, carrier);
    return matched
  }

  filterName(inputValue: string, item: any): boolean {
    if (!inputValue) return true;
    inputValue = inputValue.trim()
    let invalid = /[°"§%()\[\]{}=\\?´`'#<>|,;:+_-]+/g;
    inputValue = inputValue.replace(invalid, "");
    const regexName = new RegExp(inputValue, "i");
    return regexName.test(item.name.replace(invalid, ""));
  }

  filterPoolName(inputValue: string, item: any): boolean {
    if (!inputValue) return true;
    inputValue = inputValue.trim()
    let invalid = /[°"§%()\[\]{}=\\?´`'#<>|,;:+_-]+/g;
    inputValue = inputValue.replace(invalid, "");
    const regexName = new RegExp(inputValue, "i");
    return regexName.test((item?.poolName || "").replace(invalid, ""));
  }

  filterContact(inputValue: string, item: any): boolean {
    if (!inputValue) return true;
    inputValue = inputValue.trim();
    let invalid = /[°"§%()\[\]{}=\\?´ `'#<>|,;:+_-]+/g;
    let newValue = inputValue.replace(invalid, "");
    const regex = new RegExp(newValue, "i");
    const regexContact = new RegExp(inputValue, "i");
    return regexContact.test(item.contacts[0].contactName) || regex.test(item.contacts[0].contactEmail) || regex.test(item.contacts[0].contactPhone);
  }

  sortPriceCarrier = (a, b) => {
    if (a.price && b.price) return a.price - b.price;
    if (a.children && b.children) return a.children[0].price - b.children[0].price;
    if (a.price || b.children) {
      if (this.sortPriceDirection === 'ascend') return (a.price || 9999999) - (b.children?.[0]?.price || 9999999);
      return (a.price || 0) - (b.children?.[0]?.price || 0);
    }
    if (a.children || b.price) {
      if (this.sortPriceDirection === 'ascend') return (a.children?.[0]?.price || 9999999) - (b.price || 9999999);
      return (a.children?.[0]?.price || 0) - (b.price || 0);
    }
  }

  changeSort(event) {
    this.sortPriceDirection = event;
    this.reRenderListCarrier(this.listCarrierOriginal);
  }

  async getDatLoad() {
    const resp = await this.api.POST(`${Const.APIURI_CARRIER_BIDS}/get-dat-load-info`, {
      id: this.carrierBidId
    }).toPromise().catch(err => {
      this.showErr(err);
      this.shouldShowBtnDat = false;
    });
    this.shouldShowBtnDat = resp?.data?.isShow || false;
    this.datPostExist = resp?.data?.isCreated || false;
    return;
  }

  public shouldDisableRemoveButton() {
    const carriersRemoved = this.data?.carriers?.filter(carrier => carrier.checked);
    for (let carrier of carriersRemoved) {
      if ([Const.CarrierBidState.Accepted, Const.CarrierBidState.PlacedBid].includes(carrier?.state)) return true;
      if (carrier?.lastSent?.when) return true;
      if (carrier.price) return true;
    }
    return false;
  }

  get isIndeterminate(): boolean {
    return this.checkedStatus === CHECKSTATUS.AT_LEAST_ONE_ITEM_CHECKED
  }

  get isCheckedAll(): boolean {
    return this.checkedStatus === CHECKSTATUS.ALL_ITEMS_CHECKED
  }

  get isNoChecked(): boolean {
    return this.checkedStatus === CHECKSTATUS.NO_ITEM_CHECKED
  }

  public isDisableSendMail() {
    if(this.mode === 'not_sent') {
      if(this.data?.metadata?.notSentCount) return false;
      else return true;
    }
    if(this.mode === 'all') return false;
    return this.checkedStatus === CHECKSTATUS.NO_ITEM_CHECKED
  }

  get isCarrierMatchesTab() {
    return this.tabSelected === this.tabIndexs['carrier-matches'];
  }

  get isTruckSearchTab() {
    return this.tabSelected === this.tabIndexs['truck-search'];
  }

  get isOutreachPerformance() {
    return this.tabSelected === this.tabIndexs['outreach-performance'];
  }

  whyAdded(item) {
    if (!item?.metadata?.source) return;
    let text = '';
    switch (item.metadata.source) {
      case 'GEO_FEATURE_ASSIGNED':
        text += `This carrier was assigned to same lane in the past. LOAD: ${item.metadata.loadCode}.`;
        break;
      case 'GEO_FEATURE_PLACED':
        text += `This carrier placed bid to same lane in the past. LOAD: ${item.metadata.loadCode}.`;
        break;
      case 'EXTERNAL_MATCHING':
        text += `This carrier was matched by external system. Source: ${item.metadata?.searching?.source}`;
        break;
      case 'SAME_MARKET':
        text += `This carrier placed bid to same MARKET in the past. Source: ${item.metadata?.searching?.market}. LOAD: ${item.metadata?.loadCode}`;
        break;
      default:
        text += item.metadata.source;
    }
    if (item.metadata?.searching?.radius) {
      text += ` Radius: ${item.metadata?.searching?.radius} miles.`
    }
    return text;
  }

  public onOpenCounterDialog(item){
    DialogService.openFormDialog1(EditCounterComponent, {
      nzComponentParams: {
        carrierBid: item,
        closeOnSuccess: true,
        updateSuccess: resp => {
          this.getData();
        }
      },
    })
  }

  public onBtnCounterBidForCarrier(item) {
    DialogService.openFormDialog1(CounterForCarrierComponent, {
      nzComponentParams: {
        carrierBidItem: item,
        carrierBidInfo: this.data,
        closeOnSuccess: true,
        updateSuccess: (resp) => {
          this.getData()
        },
      },
      nzClassName: "modal",
    });
  }

  public hasCounterBidHistory(item){
    if(item?.bidAnswers && Utils.isArrayNotEmpty(item?.bidAnswers)) return true;
    return false;
  }

  public getAuthorOfBidCounter(couterItem){
    if(couterItem?.entity == 'carrier') return 'Carrier';
    if(couterItem?.entity == 'admin') return 'Warp';
    return 'N/A'
  }

  private getCounterBidHistory(item){
    let answers = item?.bidCounterOffer?.answers || [];
    if(item?.price && !item?.bidCounterOffer?.answers && !item?.bidCounterOffer?.answers.length){
      answers.unshift({
        price: item.price,
        entity: WarpConst.BidCounterOfferEntities.carrier,
        update: item?.update
      })
    }
    return answers;
  }

  public checkCanCounter(item){
    if(this.data?.job?.assignedCarrier?.carrierId) return false;
    if(item?.bidCounterOffer?.status == WarpConst.BidCounterStatus.waitAdminReply ) return true;
    if(item?.price && !item?.bidCounterOffer) return true;
    return false;
  }

  public checkCanHelpCarrierCounter(item){
    if(this.data?.job?.assignedCarrier?.carrierId) return false;
    if(item?.bidCounterOffer?.status && item?.bidCounterOffer?.status == WarpConst.BidCounterStatus.waitCarrierReply) return true;
    return false;
  }

  public isCarrierAcceptedPrice(item){
    return (item?.bidCounterOffer?.status == WarpConst.BidCounterStatus.accepted)
  }

  public isCarrierRefusedBid(item){
    return item?.state == 1;
  }

  public getCarrierAcceptedPrice(item){
    return item?.price
  }

  public  isAdminAssignCarrier(){
    return this.data?.job?.assignedCarrier?.carrierId;
  }

  public formatDate(date: string){
    if(!date) return '';
    return DateUtil.formatDate(date, Const.FORMAT_GUI_DATETIME_V3) + ' ';
  }

  public getTooltipForBidCounterAction(counter){
    return `${this.formatDate(counter?.update?.when)}${ counter?.entityName ? 'by '+counter.entityName : ''}`
  }

  public getLastAnswer(item){
    let answers = item?.bidAnswers || [];
    if(answers.length) return answers[answers.length - 1];
    return {};
  }
}
