<div *ngIf="isLoading" class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
<div *ngIf="isError" class="nodata">Cannot fetch data, please try again later</div>


  <div class="carrier-selection">
    <div class="f20 bottom20">Confirm to send mail & sms</div>

    <div nz-row [nzGutter]="12">
      <div nz-col nzSpan="24">
        <div class="box-with-title">
          <div class="box-title">Subject for Email</div>
          <div class="box-content">
            <div nz-row [nzGutter]="12">
              <div nz-col nzSpan="24">
                <nz-form-item>
                  <nz-form-label>Subject</nz-form-label>
                  <nz-form-control>
                    <input nz-input placeholder="content sent to carrier...." [(ngModel)]="sender.subject" />
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
          </div>
        </div>
      </div>
    
      <div nz-col nzSpan="24">
        <div class="box-with-title">
          <div class="box-title">Content For SMS</div>
          <div class="box-content">
            <div nz-row [nzGutter]="12">
              <div nz-col nzSpan="24">
                <nz-form-item>
                  <nz-form-label>Content</nz-form-label>
                  <nz-form-control>
                    <textarea nz-input 
                      placeholder="Content sent to carrier...." [(ngModel)]="sender.content" 
                      [nzAutosize]="{ minRows: 3, maxRows: 5 }"
                    ></textarea>
                    <div class="group-message">
                      <span nz-typography>Note: <b>[LINK]</b> will be replaced by the actual link</span>
                    </div>
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="box-with-title" *ngIf="!mode">
      <div class="box-title">Recipients <span *ngIf="isRequired('carrierIds')" class="label-mark-required"></span></div>
      <div class="box-content">
        <nz-table #nzTable nzBordered="true" [nzData]="contacts" nzSize="small" [nzLoading]="isLoading"
          [nzLoadingIndicator]="tplLoading" [nzNoResult]="tplNoData" [nzShowPagination]="contacts.length > 50 ? true :false" [nzFrontPagination]="contacts.length > 50 ? true :false">
          <ng-template #tplNoData>
            <div *ngIf="!isLoading" class="nodata"><i nz-icon nzType="search" nzTheme="outline"></i>No data</div>
          </ng-template>
          <ng-template #tplLoading>
            <div class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
          </ng-template>
          <thead>
            <tr>
              <th nzLeft nzColumnKey="checkbox" *ngIf="!isAdminReadOnlyRole" nzWidth="50px" style="text-align: center;">
                <label nz-checkbox (nzCheckedChange)="onCheckedChange($event)" [nzChecked]="checkedStatus == 2"
                  [nzIndeterminate]="checkedStatus == 1">
                </label>
              </th>
              <th nzColumnKey="name">Carrier Name</th>
              <th nzColumnKey="contactName">Carrier Contact Name</th>
              <th nzColumnKey="contactEmail">Carrier Contact Email</th>
              <th nzColumnKey="contactPhone">Carrier Contact Phone</th>
              <th nzColumnKey="info">Last sent</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of nzTable.data">
              <td nzLeft *ngIf="!isAdminReadOnlyRole" style="text-align: center;">
                <label nz-checkbox [(ngModel)]="item['checked']">
                </label>
              </td>
              <td>
                <a class="inherit" target="_blank"
                  [routerLink]="[routeAdminCarrierList, item.carrierId]">{{item.name}}</a>
              </td>
              <ng-container *ngIf="item.isNotifyViaSMS || item.isNotifyViaSMS === undefined">
                <td>
                  <input nz-input placeholder="Contact name" [(ngModel)]="item.contactName" />
                </td>
                <td>
                  <input nz-input placeholder="Contact email" [(ngModel)]="item.contactEmail" />
                </td>
                <td>
                  <input nz-input placeholder="Contact phone" [(ngModel)]="item.contactPhone" />
                </td>
              </ng-container>
              <ng-container *ngIf="item.isNotifyViaSMS === false">
                <td colspan="3">
                  <span nz-typography nzType="warning">
                    SMS is not available with this carrier. Because they have not chosen to receive text messages
                  </span>
                </td>
              </ng-container>
              <td>
                <ng-container *ngIf="item.lastSent">
                  {{$formatDate(item.lastSent?.when)}} <br />By {{item.sender?.name}}({{ item.lastSent?.type == 'sms' ? 'SMS' : 'Email' }})
                </ng-container>
                <ng-container *ngIf="!item.lastSent">
                  Never
                </ng-container>
              </td>
            </tr>
          </tbody>
        </nz-table>
      </div>
    </div>
  </div>
  <div form-footer [onProgress]="onProgress" [nzIconCancel]="null" [nzIconOK]="null"
  [canClickOK]="canClickOk && !onProgress && !isLoading" [canClickCancel]="!onProgress" [labelOK]="submitText"
  (onOK)="onBtnSend()" (onCancel)="closeDialog()"></div>
