<div class="top15 bottom15 bid-summany-selection">
  <div class="route-info-selection">
    <div class="route-info">
      <div class="route-link">
        <span style="margin-right: 6px;">Route</span>
        <a [routerLink]="[jobLink.hyperLinkUrl]" target="_blank">{{model?.job?.code}}</a>
        <span *ngIf="displayInfo.isMarketplace" class="ghost-load-label">Marketplace</span>
        <span *ngIf="!displayInfo.isMarketplace && displayInfo.isGhostLoad" class="ghost-load-label">Ghost Load</span>
      </div>
      <div class="top10">
        <span class="text-label">Customer</span>
        <span class="left5">{{ displayInfo.clientName }}</span>
        <span *ngIf="displayInfo.numOfClient && displayInfo.numOfClient > 1" class="pointer"
          [nzTooltipTitle]="tplMoreClient" nzTooltipPlacement="right" nz-tooltip nz-typography nzEllipsis>
          (+{{displayInfo.numOfClient - 1}})
        </span>
        <ng-template #tplMoreClient>
          <div *ngFor="let name of displayInfo.arrClientName">{{name}}</div>
        </ng-template>
      </div>
    </div>
    <div job-stop-timeline [stops]="model?.job?.stops" [mileage]="displayInfo.mileage"></div>
    <div>
      <div *ngIf="displayInfo.isShowRemainingTimePickup" class="bottom10 flex" nz-tooltip
        nzTooltipTitle="Time To Pickup" style="width: 160px; justify-content: flex-end;">
        <img src="/assets/img/schedule.svg" style="margin-right: 4px;" />
        <div class="bold flex" style="white-space: nowrap;">
          <div *ngIf="remainingTime.isNegative">-</div>
          <div *ngIf="remainingTime.days">{{ remainingTime.days }}d : </div>
          <div>{{ remainingTime.hours }}h : </div>
          <div>{{ remainingTime.minutes }}m : </div>
          <div>{{ remainingTime.seconds }}s</div>
        </div>
      </div>
      <div *ngIf="displayInfo.carrierSalesRep" class="flex" style="justify-content: flex-end;">
        <div style="white-space: nowrap;">
          <img src="/assets/img/account_circle_2.svg" style="margin-right: 4px;" />
          <span nz-tooltip nzTooltipTitle="Carrier Sales Rep">{{ displayInfo.carrierSalesRep }}</span>
          <span (click)="onBtnEditCarrierSalesRep()" nz-icon nzTheme="outline" nzType="edit"
            style="color: #1802d0; cursor: pointer; margin-left: 4px;" nz-tooltip
            nzTooltipTitle="Update Carrier Sales Rep"></span>
        </div>
      </div>
      <div *ngIf="!displayInfo.carrierSalesRep" style="text-align: end;">
        <i nz-icon nzType="user-add" nzTheme="outline" nz-tooltip nzTooltipTitle="Add Carrier Sales Rep"
          (click)="onBtnEditCarrierSalesRep()" class="pointer"></i>
      </div>
    </div>
  </div>
  <div class="more-info-selection">
    <div class="more-info-item width-smaller" style="position: relative;">
      <div class="text-header">Equipment</div>
      <div class="line-item equipment-info-item">
        <div class="label">Load</div>
        <div class="value">
          <ng-container *ngIf="displayInfo.vehicles?.length">
            <div *ngFor="let vehicle of displayInfo.vehicles">{{ vehicle }}</div>
          </ng-container>
          <ng-container *ngIf="!displayInfo.vehicles?.length">
            <div>N/A</div>
          </ng-container>
        </div>
      </div>
      <div class="line-item equipment-info-item">
        <div class="label">Items</div>
        <div class="value">
          {{model.job?.totalShipmentsItems?.weight}}
          <ng-container *ngIf="model?.job?.totalShipmentsItems?.units?.length">,
            {{model.job.totalShipmentsItems.units}}</ng-container>
          <!-- <div *ngIf="displayInfo.firstCommodity">
            <span>{{ displayInfo.firstCommodity }}</span>
            <ng-container *ngIf="model?.job?.totalShipmentsItems?.commodity?.length > 1">
              <span [nzTooltipTitle]="displayInfo.allCommodities" nz-tooltip
                style="margin-left: 5px;">(+{{model.job.totalShipmentsItems.commodity.length - 1}})</span>
            </ng-container>
          </div> -->
        </div>
      </div>
      <div *ngIf="displayInfo.tempRange" class="line-item equipment-info-item">
        <div class="label">Temperature</div>
        <div class="value">{{ displayInfo.tempRange }}</div>
      </div>
      <div *ngIf="displayInfo.numOfServiceOptions" class="line-item equipment-info-item">
        <div class="label">Service Options</div>
        <div class="value">
          <span>{{ displayInfo.firstServiceOptions }}</span>
          <ng-container *ngIf="displayInfo.numOfServiceOptions > 1">
            <span [nzTooltipTitle]="displayInfo.allServiceOptions" nz-tooltip
              style="margin-left: 5px;">(+{{displayInfo.numOfServiceOptions - 1}})</span>
          </ng-container>
        </div>
      </div>
      <div class="line-item equipment-info-item">
        <div class="label">
          Optional <i nz-icon nzType="info-circle" nzTheme="outline" nzTooltipPlacement="bottom"
            nzTooltipOverlayClassName="tooltip-info" style="margin-left: 10px;" class="clickable" nz-tooltip
            [nzTooltipTitle]="'The other carriers who have this equipment can also bid'"></i>
        </div>
        <div class="value">
          <span>{{ displayInfo.firstOptionalEquipments || 'N/A'}}</span>
          <ng-container *ngIf="displayInfo.numOfOptionalEquipments > 1">
            <span [nzTooltipTitle]="displayInfo.allOptionalEquipments" nz-tooltip
              style="margin-left: 5px;">(+{{displayInfo.numOfOptionalEquipments - 1}})</span>
          </ng-container>
          <a style="margin-left: 5px;" (click)="onBtnEditOptionalEquipments()">Update</a>
        </div>
      </div>
      <div *ngIf="onHold?.length" class="warning-on-hold-shipment">
        <div style="margin-right: 3px;">On-hold shipments:</div>
        <div *ngFor="let s of onHold" class="shipment-on-hold">{{ showShipmentWarpId(s) }}</div>
      </div>
    </div>
    <div class="more-info-item width-smaller">
      <ng-container *ngIf="!displayInfo.isGhostLoad">
        <div class="text-header">Rate</div>
        <div nz-row class="line-item">
          <div nz-col nzSm="12" nzLg="12" nzXl="10" nzXXl="8" class="label">Revenue</div>
          <div nz-col nzSm="12" nzLg="12" nzXl="14" nzXXl="16">
            <span nz-tooltip nzTooltipTitle="Add up grand total for all shipments on the route"
              nzTooltipPlacement="right">
              {{ displayInfo.totalShipmentCost || 'N/A'}}
            </span>
          </div>
        </div>
        <div nz-row class="line-item">
          <div nz-col nzSm="12" nzLg="12" nzXl="10" nzXXl="8" class="label">Rate / mile</div>
          <div nz-col nzSm="12" nzLg="12" nzXl="14" nzXXl="16">{{ displayInfo.totalShipmentCostPerMile || 'N/A' }}
          </div>
        </div>
      </ng-container>
      <div class="text-header top10">Market Rate</div>
      <!-- <div nz-row class="line-item">
        <div nz-col nzSm="12" nzLg="12" nzXl="10" nzXXl="8" class="label">Load to truck ratio</div>
        <div nz-col nzSm="12" nzLg="12" nzXl="14" nzXXl="16">xxx</div>
      </div> -->
      <div nz-row class="line-item">
        <div nz-col nzSm="12" nzLg="12" nzXl="10" nzXXl="8" class="label">DAT Rate</div>
        <div nz-col nzSm="12" nzLg="12" nzXl="14" nzXXl="16">
          <div *ngIf="!model?.job?.datRate?.total_charge?.mediumTotal && !model?.job?.datRate?.cost_update?.cost">-
          </div>
          <ng-container *ngIf="model?.job?.datRate?.total_charge?.mediumTotal">
            <ng-container *ngIf="!isLoadingDatRate">
              <span>{{getMoneyValue(model?.job?.datRate?.cost_update?.cost ||
                model?.job?.datRate?.total_charge?.mediumTotal)}}</span>
              <span (click)="onBtnOnUpdateTargetRate()" nz-icon nzTheme="outline" nzType="edit"
                style="color: #1802d0; cursor: pointer; margin-left: 7px;"></span>
              <span (click)="onReUpdateDatRate($event)" nz-icon nzTheme="outline" nzType="reload"
                style="color: #1802d0; cursor: pointer; margin-left: 7px;"></span>
            </ng-container>
            <ng-container *ngIf="isLoadingDatRate">
              <span nz-icon nzTheme="outline" nzType="loading"
                style="color: #1802d0; cursor: pointer; margin-left: 7px;"></span>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="model?.job?.datRate?.cost_update?.cost > 0">
            <div style="color: #adadad; margin-top: 4px;" nzTooltipTitle="DAT Rate Original" nz-tooltip
              nzTooltipPlacement="left">
              {{getMoneyValue(model?.job?.datRate?.total_charge?.mediumTotal)}}
            </div>
          </ng-container>
        </div>
      </div>
      <div nz-row class="line-item">
        <div nz-col nzSm="12" nzLg="12" nzXl="10" nzXXl="8" class="label">Historic Rate</div>
        <div nz-col nzSm="12" nzLg="12" nzXl="14" nzXXl="16">
          <ng-container *ngIf="!isLoadingAvg">
            <ng-container *ngIf="!avgCostOfRoutes">N/A</ng-container>
            <ng-container *ngIf="avgCostOfRoutes">
              {{ getMoneyValue(avgCostOfRoutes) }}
            </ng-container>
            <span (click)="onReUpdateAvgPastRate($event)" nz-icon nzTheme="outline" nzType="reload" class="reload"></span>
          </ng-container>

          <ng-container *ngIf="isLoadingAvg">
            <span nz-icon nzTheme="outline" nzType="loading" class="reload"></span>
          </ng-container>

          <a (click)="onBtnPreviousRoute()" class="btn-detail">Details</a>
        </div>
      </div>
    </div>
    <div class="more-info-item">
      <div class="text-header">Same Lane Loads <span class="left15" nz-tooltip nzTooltipTitle="Same lane loads are loads that share the same Pickup - Dropoff zip code and equipment."><i nz-icon nzType="exclamation-circle" nzTheme="outline"></i></span></div>
      <calendar-same-lane [bidId]="carrierBidId"></calendar-same-lane>
    </div>
    <div class="more-info-item">
      <div class="flex-space-between">
        <div class="text-header">Settings</div>
        <div>
          <a (click)="onBtnEditSettings()">
            <i nz-icon nzType="edit" nzTheme="outline" style="margin-right: 4px;"></i>Edit
          </a>
        </div>
      </div>
      <div class="line-item">
        <div class="label">Bid Mode</div>
        <div >{{ displayInfo.bidModeType }}</div>
      </div>
      <div class="line-item">
        <div class="label">WARP Offer</div>
        <div><b>{{ displayInfo.warpOffer || '-' }}</b></div>
      </div>
      <div class="line-item">
        <div class="label">Settings</div>
        <div>
          <div class="setting-item">
            <i class="icon-checkbox"
              [ngClass]="{'checkbox-setting-active': model?.isAllowPlaceBid , 'checkbox-setting-deactive': !model?.isAllowPlaceBid}"></i>
            <span>Allow the carrier place bid</span>
          </div>
          <div class="setting-item">
            <i class="icon-checkbox"
              [ngClass]="{'checkbox-setting-active': model?.isShowBasePrice , 'checkbox-setting-deactive': !model?.isShowBasePrice}"></i>
            <span>Show base price for carrier</span>
          </div>
          <div class="setting-item">
            <i class="icon-checkbox"
              [ngClass]="{'checkbox-setting-active': model?.isShowOnBidBoard , 'checkbox-setting-deactive': !model?.isShowOnBidBoard}"></i>
            <span>Show on bid board</span>
          </div>
          <div class="setting-item">
            <i class="icon-checkbox"
              [ngClass]="{'checkbox-setting-active': model?.isRequireCarrierAcceptLoadTender , 'checkbox-setting-deactive': !model?.isRequireCarrierAcceptLoadTender}"></i>
            <span style="line-height: 20px;">Carrier needs to accept load tender after route assignment</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>