<div *ngIf="acceptedPrice" class="box-with-title" style="padding: 20px;">
  <div nz-row [nzGutter]="12">
    <div nz-col nzSpan="8">
      <div>Accepted Price:</div>
      <h4 nz-typography>{{formatMoney(acceptedPrice)}}</h4>
      <span><nz-tag *ngIf="acceptedCarrier?.isEarliestBid" [nzColor]="'blue'">Earliest Bid</nz-tag></span>
      <span><nz-tag *ngIf="acceptedCarrier?.isLowestPrice" [nzColor]="'blue'">Lowest Price</nz-tag></span>
      <span><nz-tag *ngIf="acceptedCarrier?.isBestExperience" [nzColor]="'blue'">Best Experience</nz-tag></span>
      <div style="margin-top: 10px;">{{acceptedCarrier?.basicInfo?.name}}</div>
    </div>
    <div nz-col nzSpan="8">
      <ng-container *ngIf="compareWithLowestPrice" [ngTemplateOutlet]="statisticTemplate"
        [ngTemplateOutletContext]="{data: compareWithLowestPrice}">
      </ng-container>

      <!-- bug: wrong market price => wrong compare (FIX ME) -->
      <!-- <ng-container
        *ngIf="compareWithMarketPrice"
        [ngTemplateOutlet]="statisticTemplate"
        [ngTemplateOutletContext]="{data: compareWithMarketPrice}">
      </ng-container> -->

      <ng-container *ngIf="compareWithOfferPrice" [ngTemplateOutlet]="statisticTemplate"
        [ngTemplateOutletContext]="{data: compareWithOfferPrice}">
      </ng-container>

      <ng-template #statisticTemplate let-data='data'>
        <div style="margin-bottom: 10px;">
          <nz-tag [nzColor]="getCompareBidPriceColor(data?.status)">
            <span nz-icon [nzType]="getCompareBidPriceIcon(data?.status)" nzTheme="outline"></span>
            <span style="margin-right: 5px; margin-left: 2px;" *ngIf="data?.percent">{{data?.percent}}</span>
            <span>{{data?.text}}</span>
          </nz-tag>
        </div>
      </ng-template>
    </div>
  </div>
</div>