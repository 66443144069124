import { Component, Input } from "@angular/core";
import { Const } from "@app/const/Const";
import { BaseComponent } from "@abstract/BaseComponent";
import { InputHelper } from "@app/services/input-helper";

@Component({
  selector: '[accepted-price]',
  templateUrl: "./index.html",
  styleUrls: ["../detail-carrier-bid.component.scss", "./style.scss"],
})

export class AcceptedPriceComponent extends BaseComponent {
  @Input() acceptedPrice: any;
  @Input() acceptedCarrier: any;
  @Input() lowestPrice: any;
  @Input() avgCostOfRoutes: any;
  @Input() basePrice: any;

  get compareWithLowestPrice() {
    return this.compareWithOtherPrice('lowest price', this.lowestPrice)
  }

  get compareWithMarketPrice() {
    return this.compareWithOtherPrice('market price', this.avgCostOfRoutes)
  }

  get compareWithOfferPrice() {
    return this.compareWithOtherPrice('offer price', this.basePrice)
  }

  compareWithOtherPrice(text: string, price: number) {
    if (!price || isNaN(price) || !isFinite(price)) return
    if (this.acceptedPrice > price) {
      return {
        status: Const.compareBidPrice.more,
        percent: this.calcPercentage(this.acceptedPrice, price),
        text: `more than ${text} ${this.formatMoney(this.acceptedPrice - price)}`
      }
    }
    if (this.acceptedPrice == price) {
      return {
        status: Const.compareBidPrice.equal,
        percent: this.calcPercentage(this.acceptedPrice, price),
        text: `Equal to ${text} ${this.formatMoney(this.acceptedPrice)}`
      }
    }
    if (this.acceptedPrice < price) {
      return {
        status: Const.compareBidPrice.less,
        percent: this.calcPercentage(price, this.acceptedPrice),
        text: `less than ${text} ${this.formatMoney(price - this.acceptedPrice)}`
      }
    }
    return
  }

  calcPercentage(x, y) {
    if (!x || !y || isNaN(x) || isNaN(y)) return
    const percent = ((x / y) * 100) - 100
    if (!percent) return
    return `${(percent).toFixed(2)}%`;
  }

  formatMoney(money: number) {
    return InputHelper.formatMoney2((money).toFixed(2).toString());
  }

  getCompareBidPriceColor(status) {
    switch (status) {
      case Const.compareBidPrice.more:
        return 'red'
      case Const.compareBidPrice.equal:
      case Const.compareBidPrice.less:
        return 'green'
    }
  }

  getCompareBidPriceIcon(status) {
    switch (status) {
      case Const.compareBidPrice.more:
        return 'arrow-up'
      case Const.compareBidPrice.equal:
        return 'check'
      case Const.compareBidPrice.less:
        return 'arrow-down'
    }
  }
}