import { Component, Input } from "@angular/core";
import { Const } from "@app/const/Const";
import { BaseComponent } from "@abstract/BaseComponent";
import { InputHelper } from "@services/input-helper";
import { DateUtil } from "@services/date-utils";
import { StringULID } from "@wearewarp/types";
const API_URLS = {
  GET_CARRIER_BIDS: Const.APIURI_CARRIER_BIDS,
};

@Component({
  selector: '[bid-from-public]',
  templateUrl: "./index.html",
  styleUrls: ["../detail-carrier-bid.component.scss", "./style.scss"],
})

export class BidFromPublicComponent extends BaseComponent {
  private _model: any;
  public arrBidFromPublic: any[] = [];

  @Input() carrierBidId: StringULID;
  @Input() isShow: boolean = false;
  @Input() get model() {
    this.getDataBidPublic();
    return this._model;
  }

  set model(value) {
    this._model = value;
  }

  ngOnInit() {
    super.ngOnInit();
    this.getDataBidPublic();
  }

  $formatBidPublicDate = (isoStr) => {
    return DateUtil.displayLocalTime(isoStr, { format: Const.FORMAT_GUI_DATETIME_SHORT });
  }

  $asMoney = (money) => {
    return InputHelper.formatMoney1(money + '');
  };

  $asCarrierStatusText = (status) => {
    function capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
    const statusKey = Object.keys(Const.CarrierStatus).filter((key) => Const.CarrierStatus[key] == status)[0] || "";
    return capitalizeFirstLetter(statusKey);
  };

  $formatDate = (date) => {
    return DateUtil.dateToString(date, Const.FORMAT_GUI_DATETIME_SHORT);
  }

  isEmailSending: boolean;
  sendInviteRegisterToCarrierPublic(item) {
    if (this.isEmailSending) return;
    this.isEmailSending = true;
    let url = `${API_URLS.GET_CARRIER_BIDS}/send-register-invite-bid-public`;
    let data = { logId: item.id }
    this.api.POST(url, data).subscribe(
      resp => {
        this.showSuccess('Sent successfully');
        this.isEmailSending = false;
        this.getDataBidPublic();
      }, err => {
        this.showErr(err);
        this.isEmailSending = false;
      }
    )
  }

  private getDataBidPublic() {
    if (!this.carrierBidId) return;
    this.api.GET(`${API_URLS.GET_CARRIER_BIDS}/${this.carrierBidId}/bid-public`).subscribe(
      (response) => {
        let list_data = response?.data?.list_data || [];
        this.arrBidFromPublic = list_data;
      },
      (err) => { }
    );
  }
}