<form nz-form [formGroup]="formInput" class="form-detail" [formGroup]="formInput"
  nzLayout="vertical">

  <div class="carrier-selection">
    <div class="f20 bottom20">Counter Price</div>
        <div nz-row>
          <div nz-col nzSpan="24">
            <nz-form-item>
                <nz-form-label>{{getLabel("price")}}
                    <span *ngIf="isRequired('price')" class="label-mark-required"></span>
                </nz-form-label>
                <nz-form-control>
                    <input nz-input [placeholder]="getPlaceHolder('price')"
                    (input)="onInputChanged($event, 'price')"
                    formControlName="price"/>
                 </nz-form-control>
            </nz-form-item>
            <div class="flex-start mb-16">
                <div>
                    <span nz-icon nzType="info-circle" nzTheme="outline"></span>
                </div>
                <div>{{warningText}}</div>
            </div>
          </div>
            
        </div>
  </div>
</form>

<div form-footer [onProgress]="onProgress" [nzIconCancel]="null" [nzIconOK]="null"
  [canClickOK]="needUpdate && !onProgress" [canClickCancel]="!onProgress" labelOK="OK"
  (onOK)="onSave()" (onCancel)="closeDialog()"></div>