import { Component, Input, OnInit } from '@angular/core';
import { Const } from '@const/Const';
import { BaseFormDialog1 } from '@dialogs/base-form-dlg1';
import { InputHelper } from '@services/input-helper';
import { Const as WarpConst} from '@wearewarp/universal-libs'
@Component({
  selector: 'app-edit-counter',
  templateUrl: './edit-counter.component.html',
  styleUrls: ['./edit-counter.component.scss']
})
export class EditCounterComponent extends BaseFormDialog1{
  @Input() carrierBid: any;
  constructor() {
    super();
   }

  get isCreateNew(): boolean {
    return true;
  }

  protected formGroupDeclaration: FormGroupDeclaration = {
    price: {
      label: "Enter Counter Price",
      placeHolder: "Enter price",
      required: true,
      getValue: InputHelper.getValueMoney, formatValue: InputHelper.formatMoney
    }
  };

  ngOnInit(): void {
    super.ngOnInit();
  }

  public get warningText(){
    return 'After submitting your offer, an email will be sent to carrier to announce new update.'
  }

  public onSave(){
    const formValue = this.getFormData_JSON(true);
    let url = `${Const.APIURI_CARRIER_BIDS}/counter_offer`;
    const param = {
      token: this.carrierBid?.token,
      state: Const.CarrierBidState.PlacedBid,
      bidId: this.carrierBid?.bidId,
      action: WarpConst.BidCounterAction.counterOffer,
      entity: WarpConst.BidCounterOfferEntities.admin,
      price: formValue?.price
    };
    this.startProgress();
    this.api.POST(url, param).subscribe(
      resp => {
        super.onUpdateSuccess(resp);
        this.closeDialog();
        this.stopProgress();
      }, err => {
        this.showErr(err);
        this.stopProgress();
      }
    )

  }

}
