import { Component, Input } from '@angular/core';
import { Const } from '@const/Const';
import { BaseFormDialog1 } from '@dialogs/base-form-dlg1';
import { DateUtil } from '@services/date-utils';

@Component({
  selector: 'app-send-message',
  templateUrl: './send-message.component.html',
  styleUrls: ['./send-message.component.scss']
})
export class SendMessageComponent extends BaseFormDialog1 {
  @Input() mode: string = '';
  public isError = false;
  public isLoading = false;
  public carriers = [];
  public contacts = [];
  public sender: any = {
    name: "",
    email: ""
  };

  constructor() {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.initData()
  }

  public get canClickOk(){
    return true
  }

  public get checkedStatus(){
    const contacts = this.contacts || [];
    let countChecked = contacts.filter(contact => contact.checked).length
    if(countChecked == 0) return 0;
    if(countChecked == contacts.length) return 2;
    return 1;
  }

  normalizedString(v) {
    if(!v) return v;
    return v.replace('_', ' ').replace('-', ' ').split(' ').map(it => it.substring(0, 1).toUpperCase() + it.substring(1).toLowerCase()).join(" ");
  }

  initData(){
    let vehicles = this.model.vehicles || [];
    let vehicleText = vehicles.map(it => {
      let name = it.name;
      if(it.options?.length) {
        name += ' w/ '
        let optionText = it.options.map(op => this.normalizedString(op)).join(', ');
        name += optionText;
      }
      return name;
    }).join(', ');

    let texts = [`Route Available: ${this.model?.pickupAddressObj?.city} ${this.model?.pickupAddressObj?.state} to ${this.model?.dropoffAddressObj?.city} ${this.model?.dropoffAddressObj?.state}`];
    if(vehicleText) texts.push(vehicleText);
    texts.push(this.model?.pickupDate ? `${ DateUtil.dateToString(this.model?.pickupDate, Const.FORMAT_GUI_DATE_SHORT)} Pickup` : '(No Pickup Time)');

    this.sender = {
      ...this.sender,
      email: this.authUserEmail,
      name: this.authUserFullName,
      subject: texts.join(', '),
      content: `Hello!\nWARP has a new route available in your area!\nPlease see details at:\n[LINK]`
    }

    let carriers = this.model.carriers.filter(carrier => carrier.checked);
    
    //format lại dữ liệu để dễ sử dụng trên UI.
    carriers.map(carrier => {
      const contacts = carrier.contacts;
      contacts.forEach(contact => {
        if(contact?.contactPhone || contact?.contactEmail) this.contacts.push({
          carrierId: carrier.carrierId,
          name: carrier.name,
          contactName: contact.contactName,
          contactEmail: contact.contactEmail?.toLowerCase(),
          contactPhone: contact.contactPhone,
          lastSent: contact.lastSent,
          checked: true,
          isNotifyViaSMS: carrier?.isNotifyViaSMS
        })
      })
    })
  }

  onBtnSend(){
    this.startProgress();
    const contacts = [];
    for (const contact of this.contacts) {
      if (!contact.checked) continue; 
      else contacts.push({
        carrierId: contact.carrierId,
        contactName: contact.contactName,
        contactEmail: contact.contactEmail,
        contactPhone: contact.contactPhone,
        sender: this.sender
      });
    }
  
    let params = [
      { contacts: !this.mode ? contacts : [], content: this.sender.subject, type: 'email', sender: this.sender }, 
      { contacts: !this.mode ? contacts : [], content: this.sender.content, type: 'sms', sender: this.sender }
    ];
    
    this.api.POST(`${Const.APIURI_CARRIER_BIDS}/${this.model.id}/send-message`, { data: params, mode: this.mode }).subscribe(
      (resp) => {
        this.stopProgress();
        this.onUpdateSuccess(resp)
      },
      (err) => {
        this.showErr("There was an error during the update.")
        this.setEnableFormGroup(true);
        this.stopProgress();
        console.error(" error: ", err);
      }
    );
  }

  $formatDate = (date) => {
    return DateUtil.dateToString(date, Const.FORMAT_GUI_DATETIME_SHORT);
  }

  onCheckedChange(event){
    this.contacts.map(contact => contact['checked'] = event);
  }

  get submitText(): string {
    const totalCount = this.model?.metadata?.totalCount || 0;
    const notSentCount = this.model?.metadata?.notSentCount || 0;
    const texts = {
      all: `Send To ${totalCount > 1 ? `${totalCount} Carriers` : 'Carrier'}`,
      not_sent: `Send To ${notSentCount > 1 ? `${notSentCount} Carriers` : 'Carrier'}`
    }
    return texts[this.mode] || `Send To ${this.contacts.length > 1 ? 'Carriers' : 'Carrier'}`
  }
}
