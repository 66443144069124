<div *ngIf="arrBidFromPublic.length && isShow" class="box-with-title" style="margin-top: 50px">
  <div class="box-title">Bid From Public</div>
  <div class="box-content">
    <nz-table #nzTablePublic [nzData]="arrBidFromPublic" nzBordered="true" [nzHideOnSinglePage]="true"
      [nzSize]="'default'">
      <thead>
        <tr>
          <th>MC/DOT</th>
          <th>Contact Information</th>
          <th>Answer</th>
          <th>Price</th>
          <th>WARP Carrier Name</th>
          <th>Type</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let item of nzTablePublic.data">
          <tr>
            <td>
              <div *ngIf="item.dot">DOT: {{item.dot}}</div>
              <div *ngIf="item.mc">MC: {{item.mc}}</div>
              <div *ngIf="!item.mc && !item.dot">N/A</div>
            </td>
            <td>
              <div>Email: {{item.email || 'N/A'}}</div>
              <div>Phone Number: {{displayPhone(item.phone)}}</div>
            </td>
            <td>
              <ng-container *ngIf="item.state == 1">
                <nz-tag nzColor="error">Refused</nz-tag>
              </ng-container>
              <ng-container *ngIf="item.state == 2">
                <nz-tag nzColor="success">Accepted</nz-tag>
              </ng-container>
              <ng-container *ngIf="item.state == 3">
                <nz-tag nzColor="success">Placed Bid</nz-tag>
              </ng-container>
              <p class="small-text" style="margin-top: 5px;" *ngIf="item.insert?.when">when:
                {{$formatBidPublicDate(item.insert.when)}}</p>
            </td>
            <td>
              {{ item.price? $asMoney(item.price): '-'}}
            </td>
            <td>
              {{ item.carrierInfo?.name || '-' }}
            </td>
            <td>
              <ng-container *ngIf="!item.carrierInfo">-</ng-container>
              <ng-container *ngIf="item.carrierInfo && item.carrierInfo.status != 1">
                <nz-tag nzColor="warning">{{$asCarrierStatusText(item.carrierInfo.status)}}</nz-tag>
              </ng-container>
              <ng-container *ngIf="item.carrierInfo && item.carrierInfo.status == 1">
                <nz-tag nzColor="success">Active</nz-tag>
              </ng-container>
            </td>
            <td nzAlign="center">
              <div *ngIf="item.carrierId">
                <nz-tag [nzColor]="'green'">Moved to Carrier Matches</nz-tag>
              </div>
              <ng-container *ngIf="!item.carrierId">
                <ng-container *ngIf="!item.signupInfo">
                  <div *ngIf="!item.isMatchCarrierOrUser">
                    <div><nz-tag nzMode="default" nzColor="error">Unregistered</nz-tag></div>
                    <div class="top10">
                      <a [href]="" (click)="sendInviteRegisterToCarrierPublic(item)"
                        style="text-decoration: underline; margin-top: 10px;">
                        Invite via Email
                      </a>
                    </div>
                    <p class="small-text" style="margin-top: 5px;" *ngIf="item.lastSent">Last Sent:
                      {{$formatDate(item.lastSent.when)}}</p>
                  </div>
                  <div *ngIf="item.isMatchCarrierOrUser">
                    <nz-tag>User already existed but not login yet</nz-tag>
                  </div>
                </ng-container>
                <ng-container *ngIf="item.signupInfo?.carrierId">
                  <div>
                    Carrier has been completed signup.<br> Need review at
                    <a href="/dashboard/carriers/{{item.signupInfo.carrierId}}" target="_blank">here</a>
                  </div>
                </ng-container>
              </ng-container>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </nz-table>
  </div>
</div>