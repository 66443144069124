<div *ngIf="isLoading" class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
<div *ngIf="isError" class="nodata">Cannot fetch data, please try again later</div>

<div *ngIf="!isLoading && !isError">
  <div basic-setting
    [model]="model"
    [displayInfo]="displayInfo"
    [avgCostOfRoutes]="avgCostOfRoutes"
    (onReload)="getData()"
    [onHold]="onHold"
    [jobLink]="jobLink"
  ></div>
</div>

<div class="carrier-selection" *ngIf="!isLoading && !isError">

  <!-- Mode & Settings -->
  <div accepted-price 
    [acceptedPrice]="acceptedPrice" 
    [acceptedCarrier]="acceptedCarrier" 
    [lowestPrice]="lowestPrice"
    [avgCostOfRoutes]="avgCostOfRoutes"
    [basePrice]="basePrice"
  ></div>

  <!-- Carriers & DAT -->
  <div class="box-with-title" style="margin-top: 30px;">
    <div class="box-title flex">
      <div>Carriers <span *ngIf="isRequired('carrierIds')" class="label-mark-required"></span></div>
      <div class="carrier-tab" style="margin-left: 50px;">
        <nz-tabset [(nzSelectedIndex)]="tabSelected" [nzType]="'line'" [nzSize]="'small'">
          <nz-tab nzTitle="Carrier Matches"></nz-tab>
          <nz-tab nzTitle="Truck Search"></nz-tab>
          <nz-tab nzTitle="Outreach Performance"></nz-tab>
        </nz-tabset>
      </div>
    </div>
    <div *ngIf="isCarrierMatchesTab" class="box-content top20">
      <div class="group-box-action">
        <div class="box-action" *ngIf="!isAdminReadOnlyRole">
          <button nz-button (click)="openAddCarrierModal()" nzType="default">
            <span nz-icon nzType="plus" nzTheme="outline"></span>
            Add carrier
          </button>
          <button nz-button (click)="openAddCarrierPoolModal()" nzType="default">
            <span nz-icon nzType="plus-circle" nzTheme="outline"></span>
            Add Pools
          </button>
          <button nz-button (click)="onAddCarriersByCoverage()" nzType="default">
            <span nz-icon nzType="plus-circle" nzTheme="outline"></span>
            Add Carriers
          </button>
          <button nz-button (click)="onClickPoolCoverage()" nzType="default">
            <span nz-icon nzType="plus-circle" nzTheme="outline"></span>
            Add Pools By Coverage Area
          </button>

          <button nz-button (click)="confirmRemoveCarrier()" nzType="default" nzDanger
            [disabled]="isNoChecked || shouldDisableRemoveButton()">
            <span nz-icon nzType="delete" nzTheme="outline"></span>
            Remove selected
          </button>
        </div>
        <div class="box-action" *ngIf="!isAdminReadOnlyRole">
          <!-- <dat-post-button-v2 *ngIf="carrierBidId" [carrierBidId]="carrierBidId" [jobId]="data.jobId"></dat-post-button-v2> -->
          <button *ngIf="shouldShowBtnDat" nz-button (click)="onBtnDatLoad()" style="margin-left: 10px;"
            nzType="primary">
            <i nz-icon nzType="send" nzTheme="outline"></i>
            {{datPostExist ? "View DAT post" : "Post to DAT"}}
          </button>
          <button nz-button (click)="onBtnSendMessage()" style="margin-left: 10px;" nzType="primary"
            [disabled]="isDisableSendMail()">
            <i nz-icon nzType="send" nzTheme="outline"></i>
            Send email & sms to carriers
          </button>
        </div>
      </div>
      <nz-table 
        #nzTable nzBordered="true" 
        [nzData]="listData" 
        [nzTotal]="totalCount" [nzFrontPagination]="false"
        [nzShowPagination]="totalCount > listData.length"
        [nzLoading]="isLoadingCarrier" 
        [nzHideOnSinglePage]="true"
        [nzPageIndex]="currentPage"
        (nzPageIndexChange)="changePage($event)"
        [nzLoadingIndicator]="tplLoading" 
        [nzNoResult]="tplNoData" [nzSize]="'default'"
      >
        <ng-template #tplNoData>
          <div *ngIf="!isLoadingCarrier" class="nodata"><i nz-icon nzType="search" nzTheme="outline"></i>No data</div>
        </ng-template>
        <ng-template #tplLoading>
          <div class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
        </ng-template>
        <thead>
          <tr>
            <th *ngIf="!isAdminReadOnlyRole && !isLoadingCarrier" nzWidth="50px" [nzSelections]="listOfSelection"
              [nzChecked]="isCheckedAll" [nzIndeterminate]="isIndeterminate"
              (nzCheckedChange)="onCheckedChange($event, 'not_sent')"
              nz-tooltip="By default, only carriers who have not been sent an invitation will be selected">
            </th>
            <th nzCustomFilter nzLeft nzColumnKey="name">
              <div class="flex">
                Carrier Name
              </div>
              <input (input)="onSearch($event.target.value, 'name')" type="text" class="input-search" nz-input
                placeholder="Search by Carrier Name" nzSize="default" />
            </th>
            <th nzCustomFilter nzLeft nzColumnKey="poolName">
              Dedicated Pool
              <input (input)="onSearch($event.target.value, 'poolName')" type="text" class="input-search" nz-input
                placeholder="Search by Carrier Pool" nzSize="default" />
            </th>
            <th nzLeft nzColumnKey="contacts">
              Carrier Contact
              <input (input)="onSearch($event.target.value, 'contacts')" type="text" class="input-search" nz-input
                placeholder="Search by Carrier Contact" nzSize="default" />
            </th>
            <th nzColumnKey="completedRoute">Completed Route</th>
            <th nzColumnKey="status">Type</th>
            <th nzColumnKey="answer">Mail & SMS Invitation</th>
            <!-- <th nzColumnKey="answer">Answer</th> -->
            <th nzColumnKey="price" [nzSortOrder]="sortPriceDirection" [nzSortDirections]="['ascend', 'descend', null]"
              [nzSortFn]='sortPriceCarrier' (nzSortOrderChange)="changeSort($event)" [nzWidth]="'200px'">Bidding</th>
            <th nzColumnKey="action" *ngIf="data.jobId">Action</th>
            <th nzRight nzColumnKey="note">Note</th>
          </tr>
        </thead>
        <tbody>

          <ng-container *ngFor="let carrier of nzTable.data">
            <ng-container *ngFor="let item of mapOfExpandedData[carrier.key]">
              <tr *ngIf="(item.parent && item.parent.expand) || !item.parent">
                
                <td nzLeft *ngIf="!isAdminReadOnlyRole">
                  <label *ngIf="!isGroupByPool(item)" nz-checkbox [(ngModel)]="item['checked']"
                    (nzCheckedChange)="onItemCheckedChange($event,item)">
                  </label>
                </td>
                <!-- Carrier Name -->
                <td nzLeft [nzIndentSize]="item.level*0" [nzShowExpand]="false" [(nzExpand)]="item.expand"
                  (nzExpandChange)="onExpandChange(mapOfExpandedData[carrier.key], item, $event)"
                  [style]="item.level && item.level > 0 ? 'background-color:rgb(218, 218, 215);': ''">
                  <div *ngIf="isGroupByPool(item)" (click)="toggleChildren(carrier, item)" style="cursor: pointer;">
                    <div style="font-weight: 500;">
                      [Dedicated Pool]
                      <span>{{item.poolName}} ({{item?.children?.length || 0}})</span>
                      <i nz-icon [nzType]="item.expand ? 'up' : 'down'" nzTheme="outline"
                        *ngIf="(item.children || []).length>0" style="margin-left: 10px"></i>
                    </div>
                  </div>
                  <div style="margin-bottom: 10px;">
                    <nz-tag *ngIf="item.isEarliestBid" nzColor="blue">Earliest Bid</nz-tag>
                    <nz-tag *ngIf="item.isLowestPrice" nzColor="blue">Lowest Price</nz-tag>
                    <nz-tag *ngIf="item?.isBestExperience" [nzColor]="'blue'">Best Experience</nz-tag>
                  </div>
                  <a *ngIf="!isGroupByPool(item)" class="inherit" target="_blank"
                    [routerLink]="[routeAdminCarrierList, item.carrierId]">{{item.name}}</a>

                  <i *ngIf="whyAdded(item)" nz-icon nzType="info-circle" nzTheme="outline" nz-tooltip
                    [nzTooltipTitle]="whyAdded(item)" style="margin-left: 5px; color: #333; cursor: pointer;"></i>

                  <a *ngIf="item.goHighWayLink" style="margin-left: 15px;" [href]="item.goHighWayLink" target="_blank"
                    nz-tooltip nzTooltipTitle="Go to GoHighWay"><span nz-icon nzType="login"
                      nzTheme="outline"></span></a>



                </td>
                <!-- Dedicated Pool -->
                <td nzLeft>
                  <ng-container *ngIf="item.isDedicatedPool">
                    <nz-ribbon nzText="Dedicated">
                      <a [routerLink]="[routeAdminPools, item.poolId]" target="_blank">{{item.poolName}}</a> <br />
                      Base Rate: <b>{{ item?.basePrice ? $asMoney(item.basePrice) : '-' }}</b>
                    </nz-ribbon>
                  </ng-container>
                  <ng-container *ngIf="!item.isDedicatedPool">-</ng-container>
                </td>
                <!-- Carrier Contact -->
                <td nzLeft>
                  <ng-container *ngIf="!isGroupByPool(item)">
                    {{item.contacts?.[0]?.contactName}}<br />
                    <p class="small-text">
                      <contact-info owner="{{item.contacts?.[0]?.contactPhone}}" type="phone"
                        [scopes]="['carrier_bid']"></contact-info>
                      <contact-info owner="{{item.contacts?.[0]?.contactEmail}}" type="email"
                        [scopes]="['carrier_bid']"></contact-info>
                    </p>
                  </ng-container>
                </td>
                <!-- Completed Route -->
                <td>
                  <ng-container *ngIf="!isGroupByPool(item)">
                    <div *ngIf="item.countOfJob == 0">
                      -
                    </div>
                    <div *ngIf="item.countOfJob > 0">
                      <div style="white-space: nowrap;">
                        Completed:
                        <span nz-tooltip nzTooltipTitle="Count of Routes completed">{{ item.countOfJob }}</span> /
                        <span nz-tooltip nzTooltipTitle="Count of Routes completed for the specific lane">{{
                          item.countLane
                          }}</span>
                      </div>
                      <div display-rate-info style="margin-bottom: 4px;" [numOfLike]="item.countOfJobRateLike"
                        [numOfDislike]="item.countOfJobRateDislike">
                      </div>
                      <p class="small-text">
                        <span>Last date: {{ item.lastDate ? $formatDate(item.lastDate) : '-' }}</span>
                        <ng-container *ngIf="item.countLane">
                          <br />
                          <span>Last cost: {{ item.lastCost ? $asMoney(item.lastCost) : '-' }}</span>
                        </ng-container>
                      </p>
                    </div>
                  </ng-container>
                </td>
                <!-- Type -->
                <td>
                  <ng-container *ngIf="!isGroupByPool(item)">
                    <ng-container *ngIf="item.status != 1">
                      <nz-tag nzColor="warning">{{$asCarrierStatusText(item.status)}}</nz-tag>
                    </ng-container>
                    <ng-container *ngIf="item.status == 1">
                      <nz-tag nzColor="success">Active</nz-tag>
                    </ng-container>
                  </ng-container>
                </td>
                <!-- Last Sent -->
                <td>
                  <ng-container *ngIf="!isGroupByPool(item)">
                    <ng-container *ngIf="item.lastSent">
                      {{$formatDate(item.lastSent?.when)}} <br />
                      By {{item.sender?.name}}<br />
                      <span class="status" [ngClass]="$asSentStatusColor(item.lastSent.status)">{{
                        $asSentStatusText(item.lastSent.status) }}</span>
                      <span *ngIf="$shouldShowHistory(item.lastSent)" (click)="onBtnHistoryStatus(item)" nz-icon
                        nzType="history" nzTheme="outline" style="cursor: pointer;"></span>
                    </ng-container>
                    <ng-container *ngIf="!item.lastSent && item.lastQueued">
                      <span nz-tooltip nzTooltipTitle="This message is queued and will automatically be sent at: {{$displayEstTime(item.lastQueued?.when)}} EST">
                        Queued: <br />
                        {{$formatDate(item.lastQueued?.when)}}
                      </span>
                    </ng-container>
                    <ng-container *ngIf="!item.lastQueued && !item.lastSent">
                      Never
                    </ng-container>
                  </ng-container>
                </td>
                <!-- Bidding -->
                <td nzAlign="left">
                  <ng-container *ngIf="hasCounterBidHistory(item)  && !isCarrierRefusedBid(item)">
                    <div *ngFor="let counter of item.bidAnswers" style="text-align: left;">
                      <span nz-tooltip [nzTooltipTitle]="getTooltipForBidCounterAction(counter)" nzTooltipPlacement="rightTop">{{counter?.price ? $asMoney(counter.price): '-'}} by {{getAuthorOfBidCounter(counter)}}</span>
                    </div>
                    <div *ngIf="!isAdminAssignCarrier() && !isCarrierAcceptedPrice(item) && data?.isAllowPlaceBid" class="flex gap5 top5">
                      <button nz-button nzType="default" [disabled]="!checkCanCounter(item)" (click)="onOpenCounterDialog(item)" nzSize="small">Counter</button>
                      <button nz-button nzType="default" [disabled]="!checkCanHelpCarrierCounter(item)" nz-tooltip nzTooltipTitle="Enter Carrier's answer" nzSize="small" (click)="onBtnCounterBidForCarrier(item)"><img src="/assets/svg/question_answer.svg" alt=""></button>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="!(hasCounterBidHistory(item)) && !isCarrierAcceptedPrice(item) && !isCarrierRefusedBid(item) && !isAdminAssignCarrier()">
                    <div class="flex flex-space-between">
                      <ng-container *ngIf="item.lastSent">
                        <nz-tag nzColor="warning">Waiting for a response</nz-tag>
                      </ng-container>
                      <button nz-button  nzType="default" nzSize="small" (click)="onBtnEditItem(item)" nz-tooltip nzTooltipTitle="Enter Carrier's answer">
                        <img src="/assets/svg/question_answer.svg" alt="" style="margin-right: 3px;">
                        <span *ngIf="!item.lastSent">Enter Carrier's answer</span>
                      </button>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="isCarrierRefusedBid(item)">
                    <nz-tag nzColor="default" nz-tooltip [nzTooltipTitle]="formatDate(item?.update?.when)">Refused</nz-tag>
                  </ng-container>
                  <ng-container *ngIf="isCarrierAcceptedPrice(item)">
                    <nz-tag nzColor="success" nz-tooltip [nzTooltipTitle]="getTooltipForBidCounterAction(getLastAnswer(item))">
                      <span nz-icon nzType="check" nzTheme="outline"></span>
                      Accepted {{$asMoney(getCarrierAcceptedPrice(item))}}
                    </nz-tag>
                  </ng-container>
                </td>
                <!-- Action -->
                <td class="border-right-none" *ngIf="data.jobId">
                  <div class="flex assign-item" *ngIf="!isAdminReadOnlyRole">
                    <div
                      *ngIf="item?.carrierId && item?.carrierId != data?.job?.assignedCarrier?.carrierId && [2, 3].includes(item.state)">
                      <div *ngIf="item.status == 1 && (!displayInfo.isMarketplace || data.populated)">
                        <img class="svg-icon truck1 right10" alt="">
                        <a (click)="onBtnAcceptBid(item)" style="margin-right: 10px;">Accept and Assign Carrier</a>
                      </div>
                      <div *ngIf="item.status == 1 && displayInfo.isMarketplace && !data.populated">
                        <img class="svg-icon truck1 right10" alt="" nz-tooltip
                          nzTooltipTitle="This carrier cannot be assigned because relate to Marketplace Order">
                      </div>
                      <div *ngIf="item.status !== 1">
                        <a nz-tooltip="This carrier cannot be assigned because it is not in Active state.">-</a>
                      </div>
                    </div>
                    <div
                      *ngIf="item?.carrierId && item?.carrierId == data?.job?.assignedCarrier?.carrierId && [2, 3].includes(item.state)">
                      <span nz-typography nzType="success">
                        <span nz-icon nzType="check" nzTheme="outline"></span> Accepted
                        <p class="small-text">
                          <span>by: {{ getFullName(data?.job?.assignedCarrier?.update?.byUser) || "N/A" }}</span>
                          <br />
                          <span>when: {{ $formatDate(data?.job?.assignedCarrier?.update?.when) }}</span>
                        </p>
                      </span>
                    </div>
                  </div>
                </td>
                <!-- Note -->
                <td nzRight>
                  <div *ngIf="!isGroupByPool(item)" class="flex flex-space-between">
                    <div>
                      <p *ngIf="item.note" nz-typography nzEllipsis nzExpandable [nzEllipsisRows]="2" class="note-text">
                        {{item.note}}
                      </p>
                    </div>
                    <div *ngIf="!isAdminReadOnlyRole">
                      <button nz-button (click)="onBtnEditNote(item)" nzSize="small">
                        <i nz-icon nzType="edit" nzTheme="outline"></i>
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
            </ng-container>
          </ng-container>
        </tbody>
      </nz-table>

    </div>
    <div *ngIf="isTruckSearchTab" dat-truck-search [carrierBidInfo]="data"></div>
    <div *ngIf="isOutreachPerformance" class="box-content top20">
      <div detail-bid-outreach-performance [carrierBidId]="carrierBidId"></div>
    </div>
  </div>

  <div bid-from-public [isShow]="isCarrierMatchesTab" [carrierBidId]="carrierBidId"></div>
</div>

<div class="footer-btn" *ngIf="!isAdminReadOnlyRole && !isLoading && !isError && isCarrierMatchesTab">
  <button *ngIf="shouldShowHistory" nz-button (click)="goToHistory(model, routeAdminCarrierBidList)">
    <i nz-icon nzType="history" nzTheme="outline"></i>
    View History
  </button>
</div>
